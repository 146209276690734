var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"tab-little-header",staticStyle:{"margin-top":"0"}},[_vm._v("规则")]),_c('a-radio-group',{staticStyle:{"background":"transparent","margin-bottom":"8px"},model:{value:(_vm.dayMethod),callback:function ($$v) {_vm.dayMethod=$$v},expression:"dayMethod"}},[_c('a-radio',{attrs:{"value":"selectedTime"}},[_vm._v(" 指定时间 ")])],1),_c('br'),_c('a-form-model',{ref:"baseInfoDayForm",attrs:{"model":_vm.form,"wrapperCol":{ span: 24 }}},[_c('a-form-model-item',{attrs:{"prop":"time","rules":[
        {
          message: '请选择执行时间',
          required: true,
          trigger: 'change'
        }
      ]}},[(_vm.dayMethod === 'selectedTime')?_c('a-time-picker',{attrs:{"format":"HH:mm","value":_vm.selectTime},on:{"change":_vm.handleSelectedChange}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }