<template>
  <div class="multi-select">
    <a-checkbox
      :indeterminate="indeterminate"
      :checked="checkAll"
      @change="checkAllChange"
      style="margin-bottom: 6px;"
    >
      全选
    </a-checkbox>
    <a-checkbox-group
      v-model="checkedList"
      :options="selectOptions"
      @change="change"
    />
  </div>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    selectOptions: {
      type: Array,
      required: true
    },
    defaultChecked: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      checkedList: undefined,
      indeterminate: false,
      checkAll: false
    }
  },
  mounted () {
    if (this.defaultChecked.length) {
      this.checkedList = this.defaultChecked.concat()
      this.indeterminate =
        !!this.checkedList.length &&
        this.checkedList.length < this.selectOptions.length
      this.checkAll = this.checkedList.length === this.selectOptions.length
    }
  },
  methods: {
    change (checkedList) {
      this.indeterminate =
        !!this.checkedList.length &&
        this.checkedList.length < this.selectOptions.length
      this.checkAll = this.checkedList.length === this.selectOptions.length
      this.$emit('checkedList', this.checkedList)
    },
    checkAllChange (e) {
      const arr = []
      this.selectOptions.forEach(item => {
        arr.push(item.value)
      })
      Object.assign(this, {
        checkedList: e.target.checked ? arr : [],
        indeterminate: false,
        checkAll: e.target.checked
      })
      this.$emit('checkedList', this.checkedList)
    }
  },
  watch: {
    defaultChecked (v) {
      if (v.length) {
        this.checkedList = v.concat()
        this.indeterminate =
        !!this.checkedList.length &&
        this.checkedList.length < this.selectOptions.length
        this.checkAll = this.checkedList.length === this.selectOptions.length
      }
    }
  }
}
</script>

<style scoped>
.multi-select>>>.ant-checkbox-group-item {
  margin-bottom: 6px;
}
</style>
