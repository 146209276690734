<template>
  <div class="base-info" style="max-width: 900px">
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :wrapperCol="{ span: 20 }"
    >
      <a-form-model-item label="策略名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="策略描述" prop="description">
        <a-input v-model="form.description" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="资源" prop="source_type">
        <a-select
          v-model="form.source_type"
          :options="sourceTypeOptions"
          @change="form.source_ids = []"
          style="width: 160px"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item
        prop="source_ids"
        v-if="form.source_type"
        :wrapper-col="{ span: 20, offset: 4 }"
        :rules="[
          {
            message: '请选择' + $t(`source_type.${form.source_type}`),
            required: true,
            trigger: 'change'
          }
        ]"
      >
        <multi-source-select
          class="custom-select"
          v-model="form.source_ids"
          :source-type="form.source_type"
        ></multi-source-select>
        <p style="margin: -16px 0 0">
          当前共选中 {{ form.source_ids.length }} 台{{
            $t(`source_type.${form.source_type}`)
          }}。
        </p>
      </a-form-model-item>

      <a-form-model-item label="执行方式" prop="periodic">
        <a-radio-group v-model="form.periodic" style="background: transparent">
          <a-radio :value=false> 仅一次 </a-radio>
          <a-radio :value=true> 按周期 </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="执行时间">
        <a-form-model-item
          v-if="!form.periodic"
          prop="run_at"
          :rules="[
            {
              message: '请选择执行时间',
              required: true,
              trigger: 'change'
            }
          ]"
        >
          <a-date-picker
            :disabled-date="disabledDate"
            :disabled-time="disabledDateTime"
            :value="form.run_at"
            :allowClear="false"
            :showToday="false"
            show-time
            placeholder="选择执行时间"
            format="YYYY-MM-DD HH:mm"
            @change="onceTimeChange"
          />
        </a-form-model-item>
        <a-tabs v-else type="card" size="small" v-model="activedTab">
          <a-tab-pane key="baseInfoDayForm" tab="每天">
            <base-info-day
              ref="baseInfoDayForm"
              :detailDate="detailDate.day"
              @cron="
                v => {
                  form.cron = v
                  form.run_at = undefined
                }
              "
            ></base-info-day>
          </a-tab-pane>

          <a-tab-pane key="baseInfoWeekForm" tab="每周">
            <base-info-week
              ref="baseInfoWeekForm"
              :detailDate="detailDate.week"
              @cron="
                v => {
                  form.cron = v
                  form.run_at = undefined
                }
              "
            ></base-info-week>
          </a-tab-pane>

          <a-tab-pane key="baseInfoMonthForm" tab="每月">
            <base-info-month
              ref="baseInfoMonthForm"
              :detailDate="detailDate.month"
              @cron="
                v => {
                  form.cron = v
                  form.run_at = undefined
                }
              "
            ></base-info-month>
          </a-tab-pane>
        </a-tabs>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 21, offset: 3 }">
        <a-space size="middle">
          <a-button type="primary" @click="handleOk"> 下一步 </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import moment from 'moment'
import { sourceTypes } from '@/utils/const'
import MultiSourceSelect from '@/components/select/MultiSourceSelect'
import BaseInfoDay from './BaseInfoDay'
import BaseInfoWeek from './BaseInfoWeek'
import BaseInfoMonth from './BaseInfoMonth'
import { cronToDate } from '@/utils/cronToDate.js'

export default {
  name: 'BaseInfo',
  components: {
    MultiSourceSelect,
    BaseInfoDay,
    BaseInfoWeek,
    BaseInfoMonth
  },
  props: {
    detail: {
      type: Object
    }
  },
  data () {
    return {
      activedTab: 'baseInfoDayForm',
      form: {
        name: '',
        description: '',
        periodic: false,
        cron: '',
        source_type: sourceTypes ? sourceTypes[0] : '',
        source_ids: [],
        rules: [],
        run_at: undefined
      },
      rules: {
        name: [
          {
            message: '请输入巡检策略名称',
            required: true,
            trigger: 'blur'
          }
        ],
        periodic: [
          {
            message: '请选择执行方式',
            required: true,
            trigger: 'change'
          }
        ],
        source_type: [
          {
            message: '请选择资源类型',
            required: true,
            trigger: 'change'
          }
        ]
      },
      sourceTypeOptions: sourceTypes.map(value => {
        return {
          title: this.$t(`source_type.${value}`),
          value
        }
      }),
      detailDate: {
        activedTab: '',
        day: {},
        week: {},
        month: {}
      }
    }
  },
  methods: {
    moment,
    handleOk () {
      const promiseArr = []
      const baseInfo = new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) resolve()
        })
      })
      promiseArr.push(baseInfo)
      if (this.form.periodic) {
        const baseInfoChildren = new Promise((resolve, reject) => {
          this.$refs[this.activedTab].validate(valid => {
            if (valid) resolve()
          })
        })
        promiseArr.push(baseInfoChildren)
      }
      Promise.all(promiseArr).then(() => {
        this.$emit('baseInfo', this.form)
      })
    },
    onceTimeChange (v) {
      this.form.run_at = v ? moment(v).format('YYYY-MM-DD HH:mm') : undefined
      this.form.cron = ''
    },
    disabledDate (current) {
      return current && current < moment().subtract('day', 1)
    },
    disabledDateTime (dates) {
      // return {
      //   disabledHours: () => this.range(0, 24).splice(4, 20),
      //   disabledMinutes: () => this.range(30, 60),
      //   disabledSeconds: () => [55, 56],
      // };
    }
  },
  watch: {
    detail (v) {
      this.form.name = v.name
      this.form.description = v.description
      this.form.source_type = v.source_type
      if (v.sources) {
        this.form.source_ids = v.sources
      }
      this.form.periodic = v.periodic
      this.form.rules = v.rules
      if (v.periodic) {
        // 按周期
        this.form.cron = v.cron
        const result = cronToDate(v.cron)
        this.activedTab = result.activedTab
        this.detailDate = result
      } else {
        this.form.run_at = v.run_at
      }
    }
  }
}
</script>

<style lang="less">
.patrol-strategy-create {
  .base-info {
    .custom-select {
      .ant-select-selection--multiple {
        height: 160px;
        overflow: auto;
      }
    }

    .tab-little-header {
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      padding-left: 8px;
      margin: 16px 0 8px;

      &::before {
        content: '';
        position: absolute;
        width: 3px;
        height: 15px;
        background: #ffbe64;
        border-radius: 2px;
        top: 3px;
        left: 0px;
      }
    }
  }
}
</style>
