import moment from 'moment'

let minu = 0
let hour = 0
let day = 0
let mon = 0
let dayOfWeek = 0

export function cronToDate (cron) {
  const cronArr = cron.split(' ')
  minu = cronArr[0]
  hour = cronArr[1]
  day = cronArr[2]
  mon = cronArr[3]
  dayOfWeek = cronArr[4]
  const timeStr = hour + ':' + minu
  if (mon !== '*' || day !== '*') {
    const obj = {
      monthList: [],
      dayList: [],
      selectTime: moment(timeStr, 'HH:mm')
    }

    if (mon === '*') obj.monthList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    else obj.monthList = mon.split(',')

    if (day === '*' || day === 'L') obj.dayList = day
    else obj.dayList = day.split(',')

    return {
      activedTab: 'baseInfoMonthForm',
      month: obj,
      day: {},
      week: {}
    }
  } else if (dayOfWeek !== '*') {
    return {
      activedTab: 'baseInfoWeekForm',
      week: {
        dayOfWeekList: dayOfWeek.split(','),
        selectTime: moment(timeStr, 'HH:mm')
      },
      day: {},
      month: {}
    }
  } else {
    return {
      activedTab: 'baseInfoDayForm',
      day: {
        selectTime: moment(timeStr, 'HH:mm')
      },
      week: {},
      month: {}
    }
  }
}
