<template>
  <div>
    <a-form-model
      ref="baseInfoWeekForm"
      :model="form"
      :wrapperCol="{ span: 24 }"
    >
      <header class="tab-little-header" style="margin-top: 0">规则</header>
      <a-form-model-item
        prop="dayOfWeekList"
        :rules="[
          {
            message: '请选择',
            required: true,
            trigger: 'change'
          }
        ]"
      >
        <multi-select
          @checkedList="getWeekChecked"
          :selectOptions="weekOptions"
          :defaultChecked="form.dayOfWeekList"
        ></multi-select>
      </a-form-model-item>

      <header class="tab-little-header">指定时间</header>
      <a-form-model-item
        prop="time"
        :rules="[
          {
            message: '请选择执行时间',
            required: true,
            trigger: 'change'
          }
        ]"
      >
        <a-time-picker
          format="HH:mm"
          :value="selectTime"
          @change="handleTimeChange"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import moment from 'moment'
import MultiSelect from '@/components/select/MultiSelect'
import { weekToCron } from '@/utils/dateToCron.js'

export default {
  name: 'BaseInfoWeek',
  components: {
    MultiSelect
  },
  props: {
    detailDate: {
      type: Object
    }
  },
  data () {
    return {
      weekOptions: [
        { label: '周一', value: '2' },
        { label: '周二', value: '3' },
        { label: '周三', value: '4' },
        { label: '周四', value: '5' },
        { label: '周五', value: '6' },
        { label: '周六', value: '7' },
        { label: '周日', value: '1' }
      ],
      cronStr: '',
      form: {
        dayOfWeekList: [],
        time: undefined
      },
      selectTime: undefined
    }
  },
  mounted () {
    if (Object.keys(this.detailDate).length) {
      this.selectTime = this.detailDate.selectTime
      this.form.time = moment(this.detailDate.selectTime).format('H:m')
      this.form.dayOfWeekList = this.detailDate.dayOfWeekList
    }
  },
  methods: {
    validate (cb) {
      this.$refs.baseInfoWeekForm.validate(valid => {
        cb(valid)
      })
    },
    getWeekChecked (v) {
      this.form.dayOfWeekList = v
      if (this.form.dayOfWeekList.length && this.form.time) {
        this.cronStr = weekToCron(this.form.dayOfWeekList, this.form.time)
        this.$emit('cron', this.cronStr)
      } else this.$emit('cron', undefined)
    },
    handleTimeChange (v) {
      this.selectTime = v
      this.form.time = v ? moment(v).format('H:m') : undefined
      if (v && this.form.dayOfWeekList.length) {
        this.cronStr = weekToCron(this.form.dayOfWeekList, this.form.time)
        this.$emit('cron', this.cronStr)
      } else this.$emit('cron', undefined)
    }
  }
}
</script>
