<template>
  <div>
    <header class="tab-little-header" style="margin-top: 0">规则</header>
    <a-radio-group
      v-model="dayMethod"
      style="background: transparent; margin-bottom: 8px"
    >
      <a-radio value="selectedTime"> 指定时间 </a-radio>
      <!-- <a-radio value="repeatTime"> 重复执行 </a-radio> -->
    </a-radio-group>
    <br />
    <a-form-model
      ref="baseInfoDayForm"
      :model="form"
      :wrapperCol="{ span: 24 }"
    >
      <a-form-model-item
        prop="time"
        :rules="[
          {
            message: '请选择执行时间',
            required: true,
            trigger: 'change'
          }
        ]"
      >
        <a-time-picker
          v-if="dayMethod === 'selectedTime'"
          format="HH:mm"
          :value="selectTime"
          @change="handleSelectedChange"
        />
      </a-form-model-item>
    </a-form-model>
    <!-- <div v-else>
      每间隔
      <a-input
        :min="1"
        :default-value="1"
        type="number"
        style="width: 140px;"
      >
        <a-select slot="addonAfter" default-value="minute" style="width: 50px">
          <a-select-option value="minute">分</a-select-option>
          <a-select-option value="hour">时</a-select-option>
        </a-select>
      </a-input>
      执行一次
    </div> -->
  </div>
</template>

<script>
import moment from 'moment'
import { dayToCron } from '@/utils/dateToCron.js'

export default {
  name: 'BaseInfoDay',
  props: {
    detailDate: {
      type: Object
    }
  },
  data () {
    return {
      dayMethod: 'selectedTime',
      form: {
        time: undefined
      },
      cronStr: '',
      selectTime: undefined
    }
  },
  mounted () {
    if (Object.keys(this.detailDate).length) {
      this.selectTime = this.detailDate.selectTime
      this.form.time = this.detailDate.selectTime
    }
  },
  methods: {
    handleSelectedChange (v) {
      this.selectTime = v
      this.form.time = v ? moment(v).format('H:m') : undefined
      if (v) {
        this.cronStr = dayToCron(moment(v).format('H:m'))
        this.$emit('cron', this.cronStr)
      } else {
        this.cronStr = undefined
        this.$emit('cron', undefined)
      }
    },
    validate (cb) {
      this.$refs.baseInfoDayForm.validate(valid => {
        cb(valid)
      })
    }
  }
}
</script>
