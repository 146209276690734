let minu = 0
let hour = 0
let day = 0
let mon = 0
let dayOfWeek = 0

export function dayToCron (time, method = 'selectedTime') {
  const timeArr = time.split(':')
  switch (method) {
    case 'selectedTime':
      dayOfWeek = '*'
      mon = '*'
      day = '*'
      getHour(timeArr[0])
      getMinu(timeArr[1])
      break
    case 'repeatTime':
      break
  }
  return getStr()
}

export function weekToCron (dayOfWeekList, time) {
  day = '*'
  mon = '*'
  const timeArr = time.split(':')
  // 转换 分&时
  getHour(timeArr[0])
  getMinu(timeArr[1])
  getDayOfWeek(dayOfWeekList)
  return getStr()
}

export function monthToCron (monthList, dayList, time) {
  dayOfWeek = '*'
  const timeArr = time.split(':')
  getHour(timeArr[0])
  getMinu(timeArr[1])
  getMonth(monthList)
  getDay(dayList)
  return getStr()
}

function getMinu (_minu) {
  minu = _minu
}

function getHour (_hour) {
  hour = _hour
}

function getDayOfWeek (_dayOfWeek) {
  if (_dayOfWeek.length === 7) dayOfWeek = '*'
  else {
    dayOfWeek = _dayOfWeek.join(',')
  }
}

function getMonth (_mon) {
  if (_mon.length === 12) mon = '*'
  else {
    mon = _mon.join(',')
  }
}

function getDay (_day) {
  if (Array.isArray(_day)) {
    day = _day.join(',')
  } else {
    day = _day
  }
}

function getStr () {
  return `${minu} ${hour} ${day} ${mon} ${dayOfWeek}`
}
