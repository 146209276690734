<template>
  <div class="patrol-strategy-create">
    <div
      style="
        background: #fff;
        border-radius: 8px;
        padding: 16px;
        min-height: calc(100vh - 158px);
        position: relative;
      "
    >
      <div class="module-header" style="margin-bottom: 24px">
        {{ patrolStrategyId ? '编辑巡检策略' : '创建巡检策略' }}
      </div>

      <div class="steps-button">
        <a-steps :current="current">
          <a-step title="基本信息配置" />
          <a-step title="检查项配置" />
          <a-step title="完成" />
        </a-steps>
      </div>

      <div class="steps-content">
        <base-info
          v-show="current === 0"
          :detail="detail"
          @baseInfo="
            v => {
              form = v
              current++
            }
          "
        ></base-info>

        <div class="items-container" v-show="current === 1">
          <a-button
            type="primary"
            icon="plus"
            style="margin-bottom: 16px"
            @click="$refs.patrolItemModal.show()"
          >
            添加检查项
          </a-button>
          <a-form-model
            ref="itemsForm"
            :model="itemsForm"
            :wrapperCol="{ span: 22 }"
            :label-col="{
              span: 2,
              style: {
                textAlign: 'left'
              }
            }"
          >
            <a-form-model-item
              label="已添加"
              prop="rules"
              :rules="[
                {
                  message: '请添加检查项',
                  required: true,
                  trigger: 'change'
                }
              ]"
            >
              <a-table
                :columns="itemColumns"
                :data-source="itemsData"
                :pagination="false"
                row-key="id"
                :scroll="{ x: scrollX }"
                class="custom-table"
              >
                <template slot="condition" slot-scope="text">
                  <span>{{ $t(`compare_condition.${text}`) }}</span>
                </template>
                <template slot="operation" slot-scope="text, record">
                  <a @click="handleCancel(record)">取消添加</a>
                </template>
              </a-table>
            </a-form-model-item>
          </a-form-model>
        </div>

        <div class="result-container" v-show="current === 2">
          <a-result
            status="success"
            :title="patrolStrategyId ? '巡检策略更新成功' : '巡检策略添加成功'"
          >
            <template #extra>
              <a-button
                key="return"
                style="margin: 0 10px"
                @click="$router.push({ name: 'PatrolReport' })"
              >
                返回列表
              </a-button>
            </template>
          </a-result>
        </div>
      </div>

      <div class="steps-action">
        <a-button
          v-if="current < 2 && current >= 1"
          type="primary"
          @click="handleAdd"
          >{{ current === 0 ? '下一步' : '完成' }}</a-button
        >
        <a-button
          v-if="current === 1"
          style="margin-left: 8px"
          @click="current--"
        >
          上一步
        </a-button>
      </div>
    </div>

    <patrol-item-modal
      ref="patrolItemModal"
      @selectItems="v => (selectItems = v)"
      :source-type="form.source_type"
    ></patrol-item-modal>
  </div>
</template>

<script>
import { getPatrolItemList } from '@/api/patrol-item.js'
import {
  createPatrolStrategy,
  getPatrolStrategy,
  updatePatrolStrategy
} from '@/api/patrol-strategy.js'
import BaseInfo from './modules/BaseInfo.vue'
import PatrolItemModal from './modules/PatrolItemModal'

export default {
  name: 'PatrolStrategyCreate',
  components: {
    BaseInfo,
    PatrolItemModal
  },
  data () {
    return {
      patrolStrategyId: this.$route.params.id,
      isClone: this.$route.params.isClone ? this.$route.params.isClone : false,
      current: 0,
      getFunc: getPatrolItemList,
      itemsForm: {
        rules: []
      },
      selectedItems: [],
      itemColumns: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          width: 80,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      form: {},
      itemsData: [],
      selectItems: [],
      detail: undefined
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.itemColumns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    if (this.patrolStrategyId) this.fetch()
  },
  methods: {
    handleAdd () {
      this.$refs.itemsForm.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            active: this.form.active,
            source_type: this.form.source_type,
            source_ids: this.form.source_ids.map(item => item.id),
            rules: this.itemsForm.rules.map(item => {
              return {
                item_id: item.key
              }
            }),
            periodic: this.form.periodic,
            source_target: 'selected'
          }
          if (this.form.periodic) params.cron = this.form.cron
          else params.run_at = this.form.run_at
          if (this.form.description) params.description = this.form.description
          if (this.patrolStrategyId && !this.isClone) {
            updatePatrolStrategy(this.patrolStrategyId, params).then(res => {
              this.$message.success(res.message)
              this.current++
            })
          } else {
            createPatrolStrategy(params).then(res => {
              this.$message.success(res.message)
              this.current++
            })
          }
        }
      })
    },
    handleCancel (record) {
      const index = this.itemsData.findIndex(item => item.id === record.id)
      if (index !== -1) this.itemsData.splice(index, 1)
      const index1 = this.itemsForm.rules.findIndex(
        item => item.key === record.id
      )
      if (index1 !== -1) this.itemsForm.rules.splice(index1, 1)
    },
    fetch () {
      getPatrolStrategy(this.patrolStrategyId).then(res => {
        this.detail = res.data

        this.itemsData = this.detail.rules.map(item => {
          return {
            name: item.item.name,
            id: item.item.id
          }
        })
        this.itemsForm.rules = this.detail.rules.map(item => {
          return {
            label: item.item.name,
            key: item.item.id
          }
        })
      })
    }
  },
  watch: {
    patrolStrategyId () {},
    selectItems (v) {
      v.forEach(item => {
        if (this.itemsData.findIndex(data => data.id === item.key) === -1) {
          this.itemsData.push({ name: item.label, id: item.key })
          this.itemsForm.rules.push(item)
        }
      })
    }
  }
}
</script>

<style lang="less">
.patrol-strategy-create {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  .steps-button {
    width: 70%;
    margin-left: 15%;
    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background-color: #e6f4ff;
        border-color: #e6f4ff;
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-icon {
        background-color: rgba(0, 0, 0, 0.06);
        border-color: rgba(0, 0, 0, 0.06);
      }
    }
  }
  .steps-action {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-20px);
  }
  .steps-content {
    padding: 24px 120px 0;
    .result-container {
      position: absolute;
      top: 50%;
      transform: translate(-150px, -150px);
      left: 50%;
    }
  }
}
</style>
