<template>
  <div>
    <list-layout
      :can-operate="false"
      :in-modal="true"
      :table-operation-width="0"
      :tools-list="['reload', 'search']"
      :columns-all="columnsAll"
      :columnDefaultSelected="columnDefaultSelected"
      :get-table-data-func="getFunc"
      :refresh="refresh"
      :customer-params="params"
      :defaultSelected="tableSelectData"
      @tableSelectData="(v) => tableSelectData = v"
    >
      <template #name="{ slotProps }">
          <a-tooltip :title="slotProps.text">
            {{ slotProps.text }}
          </a-tooltip>
        </template>
      <template #priority="{ slotProps }">
        <a-tag :color="getColor(slotProps.text)">
            {{$t(`patrol_item_priority.${slotProps.text}`)}}
          </a-tag>
      </template>
      <template #recommendedValue="{ slotProps }">
        <span>{{ $t(`compare_condition_mark.${slotProps.record.condition}`) }} {{slotProps.text}}</span>
      </template>
    </list-layout>
    <!-- <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      row-key="id"
      :scroll="{ x: scrollX }"
      :total="total"
      :loading="loading"
      :row-selection="rowSelection"
      class="custom-table"
    >
      <div slot="customTitle">
        <a-tooltip v-if="!isEdit" title="编辑检查项">
          <a @click="isEdit = !isEdit"><a-icon type="edit" /></a>
        </a-tooltip>
        <a-space v-else>
          <a-tooltip title="取消修改">
            <a @click="handleCancel"><a-icon type="close"></a-icon></a>
          </a-tooltip>
          <a-tooltip title="取消修改">
            <a @click="handleOk"><a-icon type="check"></a-icon></a>
          </a-tooltip>
        </a-space>
      </div>
      <template slot="priority" slot-scope="text">
        <a-tag color="orange">{{$t(`patrol_item_priority.${text}`)}}</a-tag>
      </template>
      <template slot="condition" slot-scope="text, record">
        <span v-if="!isEdit">{{$t(compare_condition.${text}`)}}</span>
        <a-select
          class="custom-select"
          v-else
          :value="text"
          :options="conditionOptions"
          @change="e => handleChange(e, record, 'condition')"
        ></a-select>
      </template>
      <template slot="recommendedValue" slot-scope="text, record">
        <span v-if="!isEdit">{{text}}</span>
        <a-input v-else :value="text" @change="e => handleChange(e.target.value, record, 'value')" style="height: 24px;"></a-input>
      </template>
      <template slot="osType" slot-scope="text">
        {{ $t(`os_type.${text}`) }}
      </template>
    </a-table>

    <div style="height: 32px; margin-top: 24px;">
      <a-pagination
        :current="fetchParams.page"
        :pageSize="fetchParams.page_size"
        :show-size-changer="false"
        :show-total="t => `共 ${t} 条`"
        :total="total"
        @change="
          page => {
            fetchParams.page = page
            fetch()
          }
        "
        style="float: right"
      ></a-pagination>
    </div> -->
  </div>
</template>

<script>
import { compareConditions } from '@/utils/const.js'
// import { updatePatrolItem } from '@/api/patrol-item.js'
import ListLayout from '@/components/ListLayout'

export default {
  name: 'PatrolItemSimpleTable',
  props: {
    getFunc: {
      type: Function
    },
    sourceType: {
      type: String
    }
  },
  components: {
    ListLayout
  },
  data () {
    return {
      loading: false,
      isEdit: false,
      refresh: false,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'description',
          title: '描述',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'description'
          }
        },
        {
          dataIndex: 'priority',
          title: '优先级',
          width: 200,
          scopedSlots: {
            customRender: 'priority'
          }
        },
        {
          dataIndex: 'source_type',
          title: '资源类型',
          align: 'center',
          width: 160,
          scopedSlots: {
            customRender: 'sourceType'
          }
        },
        {
          dataIndex: 'method',
          title: '获取方式',
          width: 200,
          scopedSlots: {
            customRender: 'method'
          }
        },
        {
          dataIndex: 'value',
          title: '监控项',
          width: 200,
          scopedSlots: {
            customRender: 'value'
          }
        },
        {
          dataIndex: 'condition',
          title: '判断条件',
          width: 200,
          scopedSlots: {
            customRender: 'condition'
          }
        },
        {
          dataIndex: 'recommended_value',
          title: '建议值',
          width: 200,
          scopedSlots: {
            customRender: 'recommendedValue'
          }
        },
        {
          dataIndex: 'advice',
          title: '建议',
          width: 200,
          scopedSlots: {
            customRender: 'advice'
          }
        }
      ],
      // columnsAll: [
      //   {
      //     dataIndex: 'priority',
      //     title: '优先级',
      //     align: 'center',
      //     width: 80,
      //     scopedSlots: {
      //       customRender: 'priority'
      //     }
      //   },
      //   {
      //     dataIndex: 'name',
      //     title: '名称',
      //     width: 200,
      //     ellipsis: true,
      //     scopedSlots: {
      //       customRender: 'name'
      //     }
      //   },
      //   {
      //     dataIndex: 'description',
      //     title: '描述',
      //     width: 200,
      //     ellipsis: true,
      //     scopedSlots: {
      //       customRender: 'description'
      //     }
      //   },
      //   {
      //     dataIndex: 'condition',
      //     title: '判断条件',
      //     width: 120,
      //     scopedSlots: {
      //       customRender: 'condition'
      //     }
      //   },
      //   {
      //     dataIndex: 'recommended_value',
      //     title: '建议值',
      //     width: 100,
      //     scopedSlots: {
      //       customRender: 'recommendedValue'
      //     }
      //   }
      //   // {
      //   //   dataIndex: 'operation',
      //   //   slots: { title: 'customTitle' },
      //   //   width: 80,
      //   //   align: 'right'
      //   // }
      // ],
      columnDefaultSelected: [
        'name',
        'priority',
        // 'condition',
        'recommended_value',
        'description',
        'value'
      ],
      dataSource: [],
      tableSelectData: [],
      cacheData: [],
      total: 0,
      fetchParams: {
        page: 1,
        page_size: 10
      },
      conditionOptions: compareConditions.map(value => {
        return {
          title: this.$t(`compare_condition.${value}`),
          value
        }
      })
      // rowSelection: {
      //   selectedRows: [],
      //   selectedRowKeys: [],
      //   onChange: (selectedRowKeys, selectedRows) => {
      //     this.rowSelection.selectedRowKeys = selectedRowKeys
      //     this.rowSelection.selectedRows = selectedRows

      //     // if (this.tableSelectData.length) {
      //     //   this.tableSelectData = this.mergeArray(
      //     //     this.tableSelectData,
      //     //     this.rowSelection.selectedRows
      //     //   )
      //     // } else {
      //     //   this.tableSelectData = selectedRows.map(item => {
      //     //     return {
      //     //       label: item.name,
      //     //       key: item.id
      //     //     }
      //     //   })
      //     // }
      //     // this.$emit('tableSelectData', this.tableSelectData)
      //   }
      // }
    }
  },
  computed: {
    params () {
      const obj = {}
      if (this.sourceType) {
        obj.source_type = this.sourceType
      }
      return obj
    }
  },
  // computed: {
  //   scrollX () {
  //     let sum = 0
  //     this.columns.forEach(column => {
  //       sum += column.width
  //     })
  //     return sum
  //   }
  // },
  // mounted () {
  //   window.onresize = () => {
  //     this.fetch()
  //   }
  //   setTimeout(() => {
  //     window.dispatchEvent(new Event('resize'))
  //     window.onresize = null
  //   }, 300)
  // },
  methods: {
    // fetch () {
    //   this.loading = true
    //   this.getFunc(this.fetchParams)
    //     .then(res => {
    //       this.dataSource = res.data.data
    //       this.cacheData = this.dataSource.map(item => ({ ...item }))
    //       this.total = res.data.total
    //     }).finally(() => {
    //       this.loading = false
    //     })
    // },
    // handleChange (value, record, column) {
    //   const newData = [...this.dataSource]
    //   const target = newData.find(item => record.id === item.id)
    //   if (target) {
    //     target[column] = value
    //     this.dataSource = newData
    //   }
    // },
    // handleCancel () {
    //   this.isEdit = !this.isEdit
    //   this.dataSource = this.cacheData.concat()
    // },
    // handleOk () {
    //   this.isEdit = !this.isEdit
    //   this.dataSource.forEach(item => {
    //     const target = this.cacheData.find(e => item.id === e.id)
    //     if (target.value !== item.value || target.condition !== item.condition) {
    //       updatePatrolItem(item.id, item).then(res => {
    //         this.fetch()
    //       })
    //     }
    //   })
    // },
    getColor (v) {
      if (v === 'high') return 'red'
      if (v === 'medium') return 'orange'
      return 'blue'
    }
  },
  watch: {
    tableSelectData (v) {
      this.$emit('tableSelectedData', v)
    }
  }
}
</script>
<style lang="less">
.custom-select {
  .ant-select-selection--single {
    height: 24px;

    .ant-select-selection__rendered {
      line-height: 24px;
    }
  }
}
</style>
