var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-info",staticStyle:{"max-width":"900px"}},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"wrapperCol":{ span: 20 }}},[_c('a-form-model-item',{attrs:{"label":"策略名称","prop":"name"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"策略描述","prop":"description"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('a-form-model-item',{attrs:{"label":"资源","prop":"source_type"}},[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"options":_vm.sourceTypeOptions},on:{"change":function($event){_vm.form.source_ids = []}},model:{value:(_vm.form.source_type),callback:function ($$v) {_vm.$set(_vm.form, "source_type", $$v)},expression:"form.source_type"}})],1),(_vm.form.source_type)?_c('a-form-model-item',{attrs:{"prop":"source_ids","wrapper-col":{ span: 20, offset: 4 },"rules":[
        {
          message: '请选择' + _vm.$t(`source_type.${_vm.form.source_type}`),
          required: true,
          trigger: 'change'
        }
      ]}},[_c('multi-source-select',{staticClass:"custom-select",attrs:{"source-type":_vm.form.source_type},model:{value:(_vm.form.source_ids),callback:function ($$v) {_vm.$set(_vm.form, "source_ids", $$v)},expression:"form.source_ids"}}),_c('p',{staticStyle:{"margin":"-16px 0 0"}},[_vm._v(" 当前共选中 "+_vm._s(_vm.form.source_ids.length)+" 台"+_vm._s(_vm.$t(`source_type.${_vm.form.source_type}`))+"。 ")])],1):_vm._e(),_c('a-form-model-item',{attrs:{"label":"执行方式","prop":"periodic"}},[_c('a-radio-group',{staticStyle:{"background":"transparent"},model:{value:(_vm.form.periodic),callback:function ($$v) {_vm.$set(_vm.form, "periodic", $$v)},expression:"form.periodic"}},[_c('a-radio',{attrs:{"value":false}},[_vm._v(" 仅一次 ")]),_c('a-radio',{attrs:{"value":true}},[_vm._v(" 按周期 ")])],1)],1),_c('a-form-model-item',{attrs:{"label":"执行时间"}},[(!_vm.form.periodic)?_c('a-form-model-item',{attrs:{"prop":"run_at","rules":[
          {
            message: '请选择执行时间',
            required: true,
            trigger: 'change'
          }
        ]}},[_c('a-date-picker',{attrs:{"disabled-date":_vm.disabledDate,"disabled-time":_vm.disabledDateTime,"value":_vm.form.run_at,"allowClear":false,"showToday":false,"show-time":"","placeholder":"选择执行时间","format":"YYYY-MM-DD HH:mm"},on:{"change":_vm.onceTimeChange}})],1):_c('a-tabs',{attrs:{"type":"card","size":"small"},model:{value:(_vm.activedTab),callback:function ($$v) {_vm.activedTab=$$v},expression:"activedTab"}},[_c('a-tab-pane',{key:"baseInfoDayForm",attrs:{"tab":"每天"}},[_c('base-info-day',{ref:"baseInfoDayForm",attrs:{"detailDate":_vm.detailDate.day},on:{"cron":v => {
                _vm.form.cron = v
                _vm.form.run_at = undefined
              }}})],1),_c('a-tab-pane',{key:"baseInfoWeekForm",attrs:{"tab":"每周"}},[_c('base-info-week',{ref:"baseInfoWeekForm",attrs:{"detailDate":_vm.detailDate.week},on:{"cron":v => {
                _vm.form.cron = v
                _vm.form.run_at = undefined
              }}})],1),_c('a-tab-pane',{key:"baseInfoMonthForm",attrs:{"tab":"每月"}},[_c('base-info-month',{ref:"baseInfoMonthForm",attrs:{"detailDate":_vm.detailDate.month},on:{"cron":v => {
                _vm.form.cron = v
                _vm.form.run_at = undefined
              }}})],1)],1)],1),_c('a-form-model-item',{attrs:{"wrapper-col":{ span: 21, offset: 3 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v(" 下一步 ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }