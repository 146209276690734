<template>
  <a-modal
    :destroy-on-close="true"
    title="选择检查项"
    :visible="visible"
    :width="1200"
    @cancel="visible = false"
    @ok="handleOk"
  >
    <patrol-item-simple-table
      :getFunc="getFunc"
      @tableSelectedData="getSelectedData"
      :sourceType="sourceType"
    ></patrol-item-simple-table>
  </a-modal>
</template>

<script>
import { getPatrolItemList } from '@/api/patrol-item.js'
import PatrolItemSimpleTable from '@/components/table/PatrolItemSimpleTable'

export default {
  name: 'PatrolItemModal',
  components: {
    PatrolItemSimpleTable
  },
  props: {
    sourceType: {
      type: String
    }
  },
  data () {
    return {
      visible: false,
      getFunc: getPatrolItemList,
      selectedData: [],
      tableSelectedData: []
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    handleOk () {
      this.selectedData = this.tableSelectedData
      this.$emit('selectItems', this.selectedData)
      this.visible = false
    },
    getSelectedData (data) {
      this.tableSelectedData = data
    }
  }
}
</script>

<style></style>
