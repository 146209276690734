<template>
  <div>
    <a-form-model
      ref="baseInfoMonthForm"
      :model="form"
      :wrapperCol="{ span: 24 }"
    >
      <header class="tab-little-header" style="margin-top: 0">规则</header>
      <a-form-model-item
        prop="monthList"
        :rules="[
          {
            message: '请选择',
            required: true,
            trigger: 'change'
          }
        ]"
      >
        <multi-select
          @checkedList="getMonthChecked"
          :selectOptions="monthOptions"
          :defaultChecked="form.monthList"
        ></multi-select>
      </a-form-model-item>
      <header class="tab-little-header">指定日</header>
      <a-form-model-item
        prop="monthDayMethod"
        :rules="[
          {
            message: '请选择执行时间',
            required: true,
            trigger: 'change'
          }
        ]"
      >
        <a-radio-group
          v-model="form.monthDayMethod"
          @change="handleDayMethodChange"
          style="background: transparent; margin-bottom: 16px"
        >
          <a-radio value="allDay"> 每日 </a-radio>
          <a-radio value="selectDay"> 指定日 </a-radio>
          <a-radio value="lastDay"> 最后一日 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.monthDayMethod === 'selectDay'"
        prop="dayList"
        :rules="[
          {
            message: '请选择',
            required: true,
            trigger: 'change'
          }
        ]"
      >
        <a-select
          mode="multiple"
          @change="handleDaySelectedChange"
          v-model="form.dayList"
        >
          <a-select-option v-for="i in 31" :key="i.toString()">
            {{ i }}日
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <header class="tab-little-header">指定时间</header>
      <a-form-model-item
        prop="time"
        :rules="[
          {
            message: '请选择执行时间',
            required: true,
            trigger: 'change'
          }
        ]"
      >
        <a-time-picker
          format="HH:mm"
          :value="selectTime"
          @change="handleTimeChange"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import moment from 'moment'
import MultiSelect from '@/components/select/MultiSelect'
import { monthToCron } from '@/utils/dateToCron.js'

const monthOptions = []
for (let i = 1; i < 13; i++) {
  monthOptions.push({
    label: `${i}月`,
    value: i.toString()
  })
}

export default {
  name: 'BaseInfoMonth',
  components: {
    MultiSelect
  },
  props: {
    detailDate: {
      type: Object
    }
  },
  data () {
    return {
      monthOptions,
      form: {
        time: undefined,
        dayList: [],
        monthList: [],
        monthDayMethod: ''
      },
      selectTime: undefined
    }
  },
  mounted () {
    if (Object.keys(this.detailDate).length) {
      this.selectTime = this.detailDate.selectTime
      this.form.time = moment(this.detailDate.selectTime).format('H:m')
      this.form.monthList = this.detailDate.monthList
      this.form.dayList = this.detailDate.dayList
      if (this.form.dayList === '*') this.form.monthDayMethod = 'allDay'
      else if (this.form.dayList === 'L') this.form.monthDayMethod = 'lastDay'
      else if (Array.isArray(this.form.dayList)) {
        this.form.monthDayMethod = 'selectDay'
      }
    }
  },
  methods: {
    validate (cb) {
      this.$refs.baseInfoMonthForm.validate(valid => {
        cb(valid)
      })
    },
    getMonthChecked (v) {
      this.form.monthList = v
      if (
        this.form.monthList.length &&
        this.form.dayList.length &&
        this.form.time
      ) {
        this.cronStr = monthToCron(
          this.form.monthList,
          this.form.dayList,
          this.form.time
        )
        this.$emit('cron', this.cronStr)
      } else this.$emit('cron', undefined)
    },
    handleDayMethodChange (v) {
      this.form.monthDayMethod = v.target.value
      if (this.form.monthDayMethod) {
        if (this.form.monthDayMethod === 'allDay') {
          this.form.dayList = '*'
        } else if (this.form.monthDayMethod === 'lastDay') {
          this.form.dayList = 'L'
        } else {
          this.form.dayList = []
        }
        if (
          this.form.monthList.length &&
          this.form.dayList.length &&
          this.form.time
        ) {
          if (typeof this.form.time !== 'string') { this.form.time = moment(this.form.time).format('H:m') }
          this.cronStr = monthToCron(
            this.form.monthList,
            this.form.dayList,
            this.form.time
          )
          this.$emit('cron', this.cronStr)
        } else this.$emit('cron', undefined)
      } else this.form.dayList = []
    },
    handleDaySelectedChange (v) {
      if (
        this.form.monthList.length &&
        this.form.dayList.length &&
        this.form.time
      ) {
        if (typeof this.form.time !== 'string') { this.form.time = moment(this.form.time).format('H:m') }
        this.cronStr = monthToCron(
          this.form.monthList,
          this.form.dayList,
          this.form.time
        )
        this.$emit('cron', this.cronStr)
      }
    },
    handleTimeChange (v) {
      this.selectTime = v
      this.form.time = v ? moment(v).format('H:m') : undefined
      if (
        this.form.time &&
        this.form.monthList.length &&
        this.form.dayList.length
      ) {
        this.cronStr = monthToCron(
          this.form.monthList,
          this.form.dayList,
          this.form.time
        )
        this.$emit('cron', this.cronStr)
      } else this.$emit('cron', undefined)
    }
  }
}
</script>
